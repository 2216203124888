// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.container {
  max-width:1117px;
}

.navbar {
  min-height:145px;
}

.flash-message {
  .alert {
    .message {
      float: left;
      width: 95%;
    }
  }
}

// ---------- MAIN -----------

.navbar-default {
  background-color:#005897;

  .navbar-collapse {
    border:none;
  }

  .navbar-nav > li {

    a, a:hover {
      color: #fff;
    }

    &.dropdown.open {
      a, a:hover {
        background-color: #fff;
        color: #333;
      }
    }
  }

  .navbar-toggle
  {
    .icon-bar {
      background-color: #fff;
    }

    &:hover {
      background-colorl: #fff;
      .icon-bar {
        background-color: #005897;
      }
    }

  }

  .navbar-brand {
    margin-top:2rem;
  }

  .navbar-right {
    margin-top:9.4rem;
    margin-right:0;

  }

  .brand-deboeck {
    width:140px;
    float: right;
    margin-top:10.2rem;
  }
}




// -------- REGISTRATION -----------
.container.registration,
.container.admin{
  .easy-autocomplete {
    width: 100% !important;

    .eac-item .address {
      font-size:75% !important;
    }
  }

  .help-block:first-letter {
    text-transform: capitalize;
  }
}


// --------- PRODUCTS / LICENSES -------------
.container.licenses, .container.dashboard, .container.admin {

  .btn.add-license, .btn.default {
    float:right;
  }
  .bodynav
  {
    padding-left:15px;
    margin-bottom:20px;
    padding-right:15px;
  }

  .btn.manual {
    margin-bottom: 1rem;
    width: 20rem;
  }

  .manuals {
    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
      text-align: right;
    }
  }
}

// ----------- ADMIN ------------
.container.admin {
  .row.licenses {
    padding-bottom:5px;
  }

  .date-input {
    display:none;
  }

  input#code{
    width:26rem;
  }

  input#duration {
    width:10rem;
  }

  .checkbox {
    margin-top: 10px;
  }

  .dropdown-toggle {
    white-space: normal;
  }
}

.mr2 {
  margin-right: 3px;
}

